import React, {Component} from 'react';
import styled from "styled-components"
import {media} from "utils/Media"

const Wrapper = styled.div`
    background: black;
    text-align: center;
    padding: 2rem 0 .5rem 0;
    div {
        width: 90%;
        color: white;
        margin: 0 auto;
        background: black;
    }
    
    @media ${media.md} {
        padding: 2rem 0 1rem 0 ;
    }
    p {
        margin: 0!important;
    }
    strong {   
        display: block;
        color: white;
        @media ${media.lg} {
            font-size: 2.2rem;
        }
    }
    .bold {
        font-weight: 800;
    }
    h5 {
        color: white;
    }
`

class Winner extends Component {
    render() {
        return (
            <Wrapper>
                <div>
                    <strong className="h1 mb-0 black">WINNER! BEST NEW MUSICAL</strong>
                    <p className="color-orange">Olivier Awards 2022 | WhatsOnStage Awards 2022 | Broadway World Awards 2022</p>
                </div>
            </Wrapper>
        );
    }
}

export default Winner;