import React, { useEffect, useState } from 'react'
import styled from "styled-components";
import {media} from "utils/Media"
import {Modal, ModalBody} from 'reactstrap'
import Background from 'images/awards-modal/awards-modal-bkg.jpeg'
import BookTicketsBtn from './StyledButton';
import { StaticImage } from 'gatsby-plugin-image';

const StyledModal = styled(Modal)`
    overflow-y: scroll!important;
    background: rgba(0,0,0,.75);
    background-image: url(${props => props.img});
    background-size: cover;
    background-position: center;
    backdrop-filter: saturate(100%) blur(10px);
    z-index: 99999;
    //fade animation 
    animation: fade-in 1.2s cubic-bezier(0.390, 0.575, 0.565, 1.000) both;
    margin: 0;
    padding: 0;
    &::after {
        position: absolute;
        top: 0;
        left: 0;
        width: 100%;
        height: 100%;
        background-color: #000;
        opacity: .6;
        content: '';
        z-index: 2;
    }
    .awards-header {
        background: #000;
        position: relative;
        z-index: 10;
        padding: 1rem 0;
        section {
            width: 95%;
            margin: 0 auto;
            position: relative;
            p {
                color: #fff;
                margin-bottom: 0;
                text-align: left;
                font-weight: 800;
                font-family: ${props => props.theme.font.family.bold};
                @media ${media.sm} {
                    text-align: center;
                }
            }
        }
    }
    .modal-body {
        padding: 0;
    }
    .modal-content {
        background: none;
        border: none;
    }
    @media ${media.sm} {
        /* width: 80%; */
        max-width: 900px;
        margin: 0 auto;
    }
`
const StyledModalBody = styled(ModalBody)`
    background-color: transparent!important;
    position: relative;
    z-index: 5;
    text-align: center;
    .title {
        margin: 1rem 0;
        width: 90%;
        margin: 0 auto;
        h5, h6 {
            font-family: ${props => props.theme.font.family.black};
            font-weight: 900;
        }
        h6 {
            color: #fff;
            margin-top: 1.5rem;
            font-size: 1.25rem;
            @media ${media.sm} {
                font-size: 1.5rem;
            }
            @media ${media.md} {
                font-size: 1.85rem;
                margin: 2.5rem 0 0 0;
            }
        }
        h5 {
            color: ${props => props.theme.colors.secondary};
            font-size: 2rem;
            @media (min-width: 414px) {
                font-size: 2.25rem;
            }
            @media ${media.sm}  {
                font-size: 3rem;
            }
            @media ${media.md} {
                font-size: 4rem;
            }
        }
    }
    .top {
        @media ${media.sm} {
            display: grid;
            grid-template-columns: 1fr 1fr;
            grid-gap: 1rem;
        }
        @media ${media.md} {
            grid-gap: .25rem;
            .body--name {
                width: 100%;
            }
        }
    }
    .body {
        margin-top: 2rem;
        width: 90%;
        margin: 0 auto;
        &--name {
            margin-bottom: 1.5rem;
            p {
                color: ${props => props.theme.colors.secondary};
                font-family: ${props => props.theme.font.family.bold};
                margin: 0 0 .25rem 0 ;
                letter-spacing: 3%;
                font-size: 1.1rem;
                @media ${media.sm} {
                    font-size: 1.45rem;
                }
            }
            span {
                display: block;
                color: #fff;
                @media ${media.sm} {
                    font-size: 1.2rem;
                }
            }
        }
        @media ${media.sm} {
            margin-top: 2rem;
        }
    }
    .lower {
        display: grid;
        grid-template-columns: 1fr 1fr;
        grid-gap: .25rem;
    }


`
const ButtonClose = styled.button`
	position: absolute;
    border: 0;
    z-index: 10;
    right: 0.25rem;
    top: -.35rem;
    width: 32px;
    height: 32px;
    background: none;
    @media ${media.lg} {
        top: -.75rem;
        width: 48px;
        height: 48px;
    }

    &:before,
    &:after {
        content: "";
        position: absolute;
        width: 75%;
        height: 2px;
        background-color: #fff;
        transform: translate(-50%, -50%) rotate(-45deg);
    }

    &:after {
        transform: translate(-50%, -50%) rotate(45deg);
    }
`
const StylesImages = styled.div`
    width: 100%;
    display: grid;
    grid-template-columns: 1fr 1fr;
    position: relative;
    z-index: 5;
    margin-top: 1.5rem;
    section {
        position: relative;
        overflow: visible;
        &:nth-child(1) {
            width: 15rem;
            margin-left: -5rem;
            @media (min-width: 375px) {
                margin-left: -2.5rem;
            }
            @media (min-width: 414px) {
                margin-left: -2.5rem;
                margin-top: 1.25rem;
            }
        }
      
    }
    @media ${media.sm} {
        section {
            &:nth-child(1) {
            width: 20rem;
            margin-left: -2rem;
            margin-top: 3rem;
            }
            &:nth-child(2) {
                
            }
        }
    }
    @media ${media.md} {
              section {
                &:nth-child(1) {
                    width: 28rem;
                    max-width: 28rem;
                }
                &:nth-child(2) {
                    max-width: 16rem;
                    display: flex;
                    align-items: center;
                    justify-content: flex-end;
                    margin: 0 0 0 auto;
                }
              }
    }

`
const StyledButton = styled.div`
    position: relative;
    z-index: 5;
    width: 90%;
    margin: 0 auto;
    text-align: center;
    div {
        /* margin: .5rem; */
    }
`
const AwardsModal = ({isOpen, toggleModal}) => {
      return (
        <StyledModal isOpen={isOpen} toggle={toggleModal} img={Background}>
            <div className="awards-header">
                <section>
                    <p>Voting closes Friday 21 January 2022</p>
                    <ButtonClose onClick={toggleModal}>
                        <span className="sr-only">Close</span>
                    </ButtonClose>
                </section>
            </div>
            <StyledModalBody>
                <section className="title">
                    <h6>VOTE BACK TO THE FUTURE: THE MUSICAL</h6>
                    <h5>BEST NEW MUSICAL</h5>
                </section>
                <div className="body top">
                    <div className="body--name">
                        <p>BEST PERFORMER IN A MALE IDENTIFYING ROLE IN A MUSICAL</p>
                        <span>ROGER BART | OLLY DOBSON</span>
                    </div>
                    <div className="body--name">
                        <p>BEST SUPPORTING PERFORMER IN A MALE IDENTIFYING ROLE IN A MUSICAL</p>
                        <span>HUGH COLES | CEDRIC NEAL</span>
                    </div>
                </div>
            </StyledModalBody>
            <StyledModalBody>
                    <div className="body lower">
                        <div className="body--name">
                            <p>SET DESIGN</p>
                            <span>TIM HATLEY</span>
                        </div>
                        <div className="body--name">
                            <p>LIGHTING DESIGN</p>
                            <span>TIM LUTKIN</span>
                        </div>
                        <div className="body--name">
                            <p>VIDEO DESIGN</p>
                            <span>FINN ROSS</span>
                        </div>
                        <div className="body--name">
                            <p>SOUND DESIGN</p>
                            <span>GARETH OWEN</span>
                        </div>
                    </div>
            </StyledModalBody>
            <StyledButton>
                <BookTicketsBtn hide target="_blank" rel=" noopener" href="https://awards.whatsonstage.com/vote-now/">
                    Vote Now
                </BookTicketsBtn>
            </StyledButton>
            <StylesImages>
                <section>
                    <StaticImage src="../../images/awards-modal/doc-marty.png" alt='' />
                </section>
                <section>
                    <StaticImage src="../../images/awards-modal/wos.png" alt='' />
                </section>
            </StylesImages>
        </StyledModal>
      )
}

export default AwardsModal
