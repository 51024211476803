import React, {Component} from 'react';
import Lottie from 'react-lottie'
import * as animationData from "../../bodymovin/bm-megaphone/data.json";
import styled from "styled-components";
import VisibilitySensor from "react-visibility-sensor";
import {media} from "utils/Media"

const settings = {
    loop: false,
    autoplay: false,
    renderer: 'svg',
    animationData: animationData.default
};

const Content = styled.div`
    margin: 0 auto;
    width: 100%;
    max-width: 300px;
    position: relative;
    z-index: 2;
    pointer-events: none;
`

class MegaphoneAnimation extends Component {
    constructor(props) {
        super(props);

        this.state = {
            isVisible: false
        }
    }

    onChange = (isVisible) => {
        this.setState({
            isVisible: isVisible
        })
    }

    render() {
        return (

            <VisibilitySensor onChange={this.onChange} partialVisibility={true}>
                <Content>
                    <Lottie
                        options={settings}
                        isStopped={!this.state.isVisible}
                    />
                </Content>
            </VisibilitySensor>

        );
    }
}

export default MegaphoneAnimation;