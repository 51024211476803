import React, {Component} from 'react';
import {graphql, StaticQuery} from "gatsby";
import styled from "styled-components";
import {media} from "utils/Media"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FluxCapacitatorAnimation from "../Animations/FluxCapacitatorAnimation"
import Counter88Animation from "../Animations/Counter88Animation"
import StyledButton from "../shared/StyledButton";
import {Row, Col} from "reactstrap"

const Content = styled.div`
    position: relative;
    margin: 0 auto;
    text-align: center;
    
`
const MartyDoc = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
    
    @media ${media.lg} {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    
    .martyDocImage {
        width: 1022px;
    }
    
    .left, .right {
        width: 100%;
        position: relative;
        z-index: 2;
        
        @media ${media.lg} {
            width: 50%;
        }
    }
    
    .left {
        align-self: stretch;
        @media ${media.lg} {
            padding-bottom: 3rem;
        }
    }
    
    .inner {
        padding: 0 1rem;
        max-width: 630px;
        margin: 0 auto;
    }
    
    .stripe {
        width: 100%;
        height: 300px;
        z-index: 1;
        background-color: black;
        position: absolute;
        left: 0;
        bottom: 0;
        
        @media ${media.sm} {
            height: 340px;
        }
        
        @media ${media.md} {
            height: 400px;
        }
        
        @media ${media.lg} {
            height: 200px;
        }
        
        @media ${media.xl} {
            height: 200px;
        }
    }
`

class FluxBlockTheatres extends Component {
    render() {
        return (
            <Content className="my-3 my-md-5">
                <MartyDoc>
                    <div className="left d-none d-lg-block">
                        <FluxCapacitatorAnimation smaller/>
                        <GatsbyImage image={getImage(this.props.data.martyDocBgLg)}
                            alt=""
                            title=""
                            className="img-fluid d-none d-lg-block martyDocImage"
                        />
                    </div>
                    <div className="right">
                        <div className="inner">
                            <FluxCapacitatorAnimation className="d-block d-lg-none" smaller/>
                            <p className="mb-lg-4">Get ready for 1.21 <strong>“GIGAWATTS OF MUSICAL ENTERTAINMENT”</strong> 
(WhatsOnStage) at the Adelphi Theatre.</p>

                            <p className="mb-lg-4">Welcome to Hill Valley, 1985. When young Marty McFly (Olly Dobson, <em>Matilda: The Musical</em>) finds himself transported back to 1955 in a time machine built by eccentric scientist Doc Brown (Tony Award winner Roger Bart, <em>The Producers</em>), he accidentally changes the course of history. Now he’s trapped in a race against time to fix the present, escape the past and send himself... back to the future!</p>

                            <p className="mb-lg-4">This spectacular and hilarious musical adventure is directed by Tony Award winner John Rando and features a new score from Grammy Award winners Alan Silvestri and Glen Ballard. The ground-breaking stagecraft is by Chris Fisher (<em>Harry Potter and the Cursed Child</em>), who brings the iconic DeLorean to the stage at 88mph!</p>

                            <p className="mb-lg-4"><strong>“ONCE YOU’VE SEEN BACK TO THE FUTURE: THE MUSICAL, YOU’LL WANT TO JUMP IN THE DELOREAN AND RELIVE THE FUN ALL OVER AGAIN!”</strong> (Manchester Evening News)</p>
                            <p className="mb-lg-4">&nbsp;</p>

                            <p className="mb-0 text-uppercase font-weight-bold">PRICES FROM £19.55</p>

                            <StyledButton $hide className="mt-2 mb-5 mx-auto" $inverse target="_blank" rel=" noopener" href={this.props.booking}>
                                Book Tickets
                            </StyledButton>

                            <div style={{opacity: 0}} className="d-none d-lg-block mb-lg-2">
                                <Counter88Animation/>
                            </div>
                        </div>
                    </div>
                    <div className="left d-lg-none position-relative">
                        <GatsbyImage image={getImage(this.props.data.martyDocBgXs)}

                            alt=""
                            title=""
                            className="img-fluid d-sm-none"
                        />
                        <GatsbyImage image={getImage(this.props.data.martyDocBgSm)}

                            alt=""
                            title=""
                            className="img-fluid d-none d-sm-block d-lg-none"
                        />
                        <div className="d-lg-none">
                            <Counter88Animation/>
                        </div>
                    </div>
                    <div className="stripe">
                        <div className="d-none d-lg-block mb-lg-2 px-4">
                            <Row>
                                <Col xs={12} lg={6}>&nbsp;</Col>
                                <Col xs={12} lg={6}><Counter88Animation/></Col>
                            </Row>
                        </div>
                    </div>
                </MartyDoc>
            </Content>

        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                martyDocBgXs: file(relativePath: { eq: "HomePage/pic-martydoc-576-new.png" }) {
                    childImageSharp {
                         gatsbyImageData(width: 575)
                    }
                }
                martyDocBgSm: file(relativePath: { eq: "HomePage/pic-martydoc-991-new.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991)
                    }
                }
                martyDocBgLg: file(relativePath: { eq: "HomePage/pic-martydoc-1920-new.png" }) {
                    childImageSharp {
                       gatsbyImageData(width: 1028)
                    }
                }
            }
		`}
        render={data => (
            <FluxBlockTheatres {...props} data={data}/>
        )}
    />
)
