import React, {Component} from 'react';
import Slider from 'react-slick'
import styled, { css } from "styled-components";
import {media} from "utils/Media"
import {graphql, StaticQuery} from "gatsby";
import next from '../../images/icons/next.svg'
import star from '../../images/icons/star.svg'

const settings = {
    slidesToShow: 1,
    dots: false,
    arrows: true,
    swipe: true,
    swipeToSlide: true
}

const Content = styled.div`
    text-align: center;
    background: ${props => props.theme.colors.black};
    ${props => props.$opacity && css`
        opacity: 0;
        background: transparent!important;
    `}
`

const TestimonialSlider = styled(Slider)`
    position: relative;
    z-index: 2;
    text-align: center;
    margin: 0 auto;
    padding: 1rem;
    width: 100%;
    max-width:1000px;
    .slider-item {
        width: calc(100% - 80px) !important;
    }
    
    .stars {
        margin: 0 auto 1rem;
        display: flex;
        justify-content: center;
        
        img {
            width: 38px;
            @media ${media.sm} {
                width: 38px;
            }
        }
    }
    
    h3 {
        color: ${props => props.theme.colors.white};
        @media ${media.sm} {
            font-size: 1.5rem;
        }
        @media ${media.xl} {
            font-size: 2rem;
            max-width:850px;
            margin: 0 auto;
        }
    }
    
    p {
        color: ${props => props.theme.colors.orange};
    }
    
    .slick-track {
        display: flex;
        align-items: center;
        flex-wrap: nowrap;
        justify-content: center;
    }
    
    .slick-slide{
        padding: 0 25px;
        transition: all 0.3s ease;
        height: auto;
    }
    
    .slick-prev, .slick-next {
        width: 31px;
        height: 25px;
        top: 50%;
        z-index: 2;
        
        @media ${media.sm} {
            width: 42px;
            height: 31px;
        }
        
        &:focus {
            outline: 5px auto -webkit-focus-ring-color !important;
        }
    }
    
    .slick-next {
        right: 20px;
        
        &:before {
            content: '';
            background: url(${next}) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: 0;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    
    .slick-prev {
        left: 20px;
        
        &:before {
            transform: rotate(180deg);
            content: '';
            background: url(${next}) no-repeat center center;
            background-size: contain;
            position: absolute;
            left: 0;
            top: 0;
            width: 100%;
            height: 100%;
            
            @media ${media.xl} {
                left: -20px;
            }
        }
    }
`


class TestimonialBlockFamilies extends Component {

    getStars(stars) {
        let content = [];

        for (let i = 1; i <= stars; i++) {
            content.push(<img key={i} src={star} alt="" title=""/>)
        }
        return content;
    }

    render() {
        const sliderItems = this.props.data.allTestimonialFilmFansJson.edges.map(({node}, i) => {
            return <div
                key={i}
                className="slider-item"
            >
                {node.stars > 0 &&
                <div className="stars">
                    {this.getStars(node.stars)}
                </div>
                }
                <h3>{node.copy}</h3>
                <p className="small">{node.person}</p>
            </div>
        })
        return (
            <Content id="slider" {...this.props}>
                <TestimonialSlider
                    {...settings}
                    className="PhotoGallery"
                    ref={slider => (this.carousel = slider)}
                >
                    {sliderItems}
                </TestimonialSlider>
            </Content>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allTestimonialFilmFansJson {
                    edges {
                        node {
                            stars
                            copy
                            person
                        }
                    }
                }
            }
		`}
        render={data => (
            <TestimonialBlockFamilies data={data} {...props}  />
        )}
    />
)