import React from 'react'
import styled, { css } from 'styled-components';

import StyledButton from "../shared/StyledButton";

import { media } from "utils/Media";

const StyledButtonCustom = styled(StyledButton)`
  max-width: 320px;
`;

const Box = styled.div`
  width: 100%;
  background: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.red},
    ${(props) => props.theme.colors.yellow}
  );
  padding: 3px;
  margin: 3rem 0 2rem;
  ${props => props.narrow && css`
    max-width: 900px;
    margin-right: auto;
    margin-left: auto;
    `}
  .helper {
    background: ${(props) => props.theme.colors.black};
    padding: 1rem;
    position: relative;
    color: ${(props) => props.theme.colors.white};

    @media ${media.sm} {
      display: flex;
      justify-content: center;
      align-items: center;
    }

    h3 {
      color: ${(props) => props.theme.colors.white};
    }

    .items {
      @media ${media.sm} {
        width: 50%;
      }
    }
  }
  ${(props) =>
    props.$smaller &&
    css`
      max-width: 500px;
      margin: 2rem auto;
    `}

    .inner {
      background: #000;
      color: #fff;
      padding-bottom: 2rem;
      p, h3 {
        color: #fff;
      }
      .svg-wrapper {
        svg {
          width: 550px
        }
      }
      .img-wrapper {
        max-width: 550px;
        width: 90%;
        margin: 2rem auto 2rem auto;

        background: linear-gradient(
    to bottom,
    ${(props) => props.theme.colors.red},
    ${(props) => props.theme.colors.yellow}
  );
  padding: 3px;
      }
      .wrapper {
        img {
        }
      }
      @media ${media.md} {
        display: grid;
        grid-template-columns: 1fr 1fr;
      }
    }
`;

const NewTicketBox = styled(Box)`

  .helper {
    padding: 1rem 0.5rem;
    
    margin: 0;
    height: 100%;
    h3 {
      font-size: 22px;
    }
    div {
      display: flex;
      flex-direction: column;
    justify-content: center;
    align-items: center;
    }
  }
`

const TicketFlowsStyled = styled.section`
  width: 100%;
  max-width: 1500px;
  margin: 0 auto;
  padding: 3rem 0 0 0;
  text-align: center;
  h2 {
    padding: 2rem 0 ;
    font-size: 32px;
    @media ${media.md} {
      font-size: 46px;
    }
  }
  .wrapper {
    width: 90%;
    margin: 0 auto;
  }
  @media ${media.md} {
    .wrapper {
      display: grid;
      grid-template-columns: 1fr 1fr 1fr;
      grid-gap: 1rem;
    }
    }
`

export default function TicketFlows() {
  return (
    <TicketFlowsStyled >
    <h2>Great Scott! GET YOUR TICKETS YESTERDAY!    </h2>
    {/* <h2>Great Scott! Grab Your Ticket Deals Now!</h2> */}
      <div className="wrapper">
      
    <NewTicketBox $smaller>
      <div className="helper">
      <div className="item pb-3">
      <h3 style={{ paddingBottom: "0.5rem" }}>WEEKEND AVAILABILITY FROM £19.85</h3>
            <StyledButtonCustom
              target="_blank"
              rel=" noopener"
              href="https://ticketing.lwtheatres.co.uk/event/218/?PROMO=BTTFWEEKEND"
            >
              Book Now
            </StyledButtonCustom>
        </div></div>
      </NewTicketBox>
      <NewTicketBox $smaller>
      <div className="helper">
      <div className="item pb-3">
      <h3 style={{ paddingBottom: "0.5rem" }}>SEE US THIS MONTH FOR BEST AVAILABILITY AND PRICES</h3>
            <StyledButtonCustom
              target="_blank"
              rel=" noopener"
              href="https://ticketing.lwtheatres.co.uk/event/218/?PROMO=BTTFSEPT&date=2024-09"
            >
              Book Now
            </StyledButtonCustom>
        </div></div>
      </NewTicketBox>
      <NewTicketBox $smaller>
      <div className="helper">
      <div className="item pb-3">
      <h3 style={{ paddingBottom: "0.5rem" }}>SAVE WHEN YOU BOOK 6 OR MORE TICKETS | GREAT SEATS FROM £50</h3>
            <StyledButtonCustom
              target="_blank"
              rel=" noopener"
              href="https://ticketing.lwtheatres.co.uk/event/218?PROMO=BTTFSIX&date=2024-09"
            >
              Book Now
            </StyledButtonCustom>
        </div></div>
      </NewTicketBox>
      </div>
    </TicketFlowsStyled>
  )
}
