import React from "react"
import { Container, Row, Col } from "reactstrap"
import styled from "styled-components"
import StyledButton from "components/shared/StyledButton"

const Bg = styled.div`
    background-color: black;
    color: white;
`

const Flash = styled.div`
    background: linear-gradient(190deg, #FF0000 17.93%, #FF7503 38.01%, #FFD200 60.03%);
    background-size: 100% 200%;
    padding: .2rem;
    margin: 2rem;

    h3 {
        color: white;
    }

    .inner {
        background: black;
        padding: 1rem 2rem;
    }
`

const FlashVoucher = () => {
    return(
        <Bg>
            <Container>
                <Row className="justify-content-center text-center">
                    <Col xs="auto">
                        <Flash>
                            <div className="inner">
                                <h3 className="mb-0">
                                    ANYTIME TICKETS
                                </h3>
                                <p>Give the gift of time travel this Christmas</p>
                                <StyledButton target="_blank" rel=" noopener" href="https://lwtheatres.co.uk/back-to-the-future-anytime-tickets/">
                                    Buy now
                                </StyledButton>
                            </div>
                        </Flash>
                    </Col>
                </Row>
            </Container>
        </Bg>
    )
}

export default FlashVoucher