import React, { Component } from "react";
import Layout from "../../components/Layout/Layout";
import LandingBlock from "../../components/HomePage/LandingBlock";
import Winner from "../../components/HomePage/Winner";
import FluxBlock from "../../components/HomePage/FluxBlock";
import GreatScottBlock from "../../components/HomePage/GreatScottBlock";
import { window } from "browser-monads";
import Cookies from "universal-cookie";
import LandingBlockFilmFans from "../../components/HomePage/LandingBlockFilmFans";
import FluxBlockFilmFans from "../../components/HomePage/FluxBlockFilmFans";
import MegaphoneBlockFilmFans from "../../components/HomePage/MegaphoneBlockFilmFans";
import LandingBlockFamilies from "../../components/HomePage/LandingBlockFamilies";
import FluxBlockFamilies from "../../components/HomePage/FluxBlockFamilies";
import MegaphoneBlockFamilies from "../../components/HomePage/MegaphoneBlockFamilies";
import LandingBlockTheatres from "../../components/HomePage/LandingBlockTheatres";
import TicketFlows from "../../components/HomePage/ticket-flows";
import FluxBlockTheatres from "../../components/HomePage/FluxBlockTheatres";
import MegaphoneBlockTheatres from "../../components/HomePage/MegaphoneBlockTheatres";
import TestimonialBlockFamilies from "../../components/HomePage/TestimonialBlockFamilies";
import TestimonialBlockTheatres from "../../components/HomePage/TestimonialBlockTheatres";
import TestimonialBlockFilmFans from "../../components/HomePage/TestimonialBlockFilmFans";
import AudioBlock from "../../components/HomePage/AudioBlock";
import StyledButton from "../../components/shared/StyledButton";
import ReviewSlider from "../../components/HomePage/ReviewSlider";
import AwardsModal from "../../components/shared/AwardsModal";
import { Helmet } from "react-helmet";
import ChildrenInNeed from "../../components/HomePage/ChildrenInNeed";
import QRcodeBlock from "../../components/shared/QRcodeBlock";

const cookies = new Cookies();
const COOKIE_DOMAIN = "backtothefuturemusical.com";
let expiry = new Date();
expiry = new Date(expiry.setMonth(expiry.getMonth() + 3));

class IndexPage extends Component {
  constructor(props) {
    super(props);

    this.state = {
      audience: "other",
      musicStopped: false,
      videoStopped: false,
      modalOpen: true,
    };

    this.stopMusic = this.stopMusic.bind(this);
    this.stopVideo = this.stopVideo.bind(this);
  }

  componentDidMount() {
    if (!cookies.get("awardsModal")) {
      this.setState({
        modalOpen: false,
      });
    }
    const urlParams = new URLSearchParams(window.location.search);
    let audience;

    if (typeof cookies.get("audience") === "undefined") {
      if (urlParams.get("audience") !== null) {
        // Decide audience by the url string
        if (urlParams.get("audience") === "film-fans") {
          audience = "film-fans";
        } else if (urlParams.get("audience") === "theatres") {
          audience = "theatres";
        } else if (urlParams.get("audience") === "families") {
          audience = "families";
        } else {
          audience = "other";
        }
      } else {
        audience = "other";
      }

      cookies.set("audience", audience, {
        expires: expiry,
        domain: COOKIE_DOMAIN,
      });
    } else {
      audience = cookies.get("audience");
    }

    this.setState({
      audience,
    });

    window.dataLayer = window.dataLayer || [];
    window.dataLayer.push({
      event: "setAudience",
      audience: audience,
      nonInteraction: "True",
    });
  }

  stopMusic = (musicStopped) => {
    this.setState({
      musicStopped,
    });
  };

  stopVideo = (videoStopped) => {
    this.setState({
      videoStopped,
    });
  };

  componentDidUpdate(prevProps, prevState) {
    if (prevState.musicStopped !== this.state.musicStopped) {
      this.setState({
        musicStopped: this.state.musicStopped,
      });
    }
  }
  toggleModal = (e) => {
    e.preventDefault();
    cookies.set("awardsModal", true, {
      expires: expiry,
      domain: COOKIE_DOMAIN,
    });
    this.setState({
      modalOpen: !this.state.modalOpen,
    });
  };
  render() {
    switch (this.state.audience) {
      case "film-fans": {
        return (
          <Layout
            title=" Back to the Future The Musical | West End, London"
            description="The official website for Back to the Future The Musical. Now open at the Adelphi Theatre in London."
            booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_FilmFans"
          >
            <LandingBlockFilmFans />
            <TestimonialBlockFilmFans />
            <FluxBlockFilmFans booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_FilmFans" />
            <GreatScottBlock
              stopMusic={this.stopMusic}
              stopVideo={this.stopVideo}
              videoStopped={this.state.videoStopped}
              musicStopped={this.state.musicStopped}
              booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_FilmFans"
            />
            <MegaphoneBlockFilmFans />
            <AudioBlock
              stopMusic={this.stopMusic}
              stopVideo={this.stopVideo}
              videoStopped={this.state.videoStopped}
              musicStopped={this.state.musicStopped}
              title="Music"
              $inverse
            />
            <div className="py-5 mx-auto text-center">
              <h3 className="mb-0">Book your tickets yesterday!</h3>

              <StyledButton
                $hide
                className="mt-2 mt-md-4 mx-auto"
                $inverse
                target="_blank"
                rel=" noopener"
                href="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_FilmFans"
              >
                Book Tickets
              </StyledButton>
            </div>
          </Layout>
        );
      }
      case "theatres": {
        return (
          <Layout
            title=" Back to the Future The Musical | West End, London"
            description="The official website for Back to the Future The Musical. Now open at the Adelphi Theatre in London."
            booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_Theatre"
          >
            <LandingBlockTheatres />
            <TestimonialBlockTheatres />
            <FluxBlockTheatres booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_Theatre" />
            <GreatScottBlock
              otherImage={true}
              title="IT’s TIME TO GET BACK TO THE THEATRE!"
              booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_Theatre"
              videoId="S0JpvMeshb4"
              stopMusic={this.stopMusic}
              stopVideo={this.stopVideo}
              videoStopped={this.state.videoStopped}
              musicStopped={this.state.musicStopped}
            />
            <MegaphoneBlockTheatres />
            <AudioBlock
              stopMusic={this.stopMusic}
              stopVideo={this.stopVideo}
              videoStopped={this.state.videoStopped}
              musicStopped={this.state.musicStopped}
              title="Music"
              $inverse
            />
            <div className="py-5 mx-auto text-center">
              <h3 className="mb-0">Book your tickets yesterday!</h3>

              <StyledButton
                $hide
                className="mt-2 mt-md-4 mx-auto"
                $inverse
                target="_blank"
                rel=" noopener"
                href="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_Theatre"
              >
                Book Tickets
              </StyledButton>
            </div>
          </Layout>
        );
      }
      case "families": {
        return (
          <Layout
            title=" Back to the Future The Musical | West End, London"
            description="The official website for Back to the Future The Musical. Now open at the Adelphi Theatre in London."
            booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_Family"
          >
            <LandingBlockFamilies />
            <TestimonialBlockFamilies />
            <FluxBlockFamilies booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_Family" />
            <GreatScottBlock
              stopMusic={this.stopMusic}
              stopVideo={this.stopVideo}
              videoStopped={this.state.videoStopped}
              musicStopped={this.state.musicStopped}
              booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_Family"
            />
            <MegaphoneBlockFamilies />
            <AudioBlock
              stopMusic={this.stopMusic}
              stopVideo={this.stopVideo}
              videoStopped={this.state.videoStopped}
              musicStopped={this.state.musicStopped}
              title="Music"
              $inverse
            />
            <div className="py-5 mx-auto text-center">
              <h3 className="mb-0">Book your tickets yesterday!</h3>

              <StyledButton
                $hide
                className="mt-2 mt-md-4 mx-auto"
                $inverse
                target="_blank"
                rel=" noopener"
                href="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?utm_source=BTTFemail&utm_medium=email&utm_campaign=BTTFAudience_Family"
              >
                Book Tickets
              </StyledButton>
            </div>
          </Layout>
        );
      }
      default: {
        return (
          <>
            <Helmet>
              <link rel="alternate" href={`https://www.backtothefuturemusical.com/new-york/`} hreflang="en-us" />
              <link rel="alternate" href={`https://www.backtothefuturemusical.com/london/`} hreflang="en-gb" />
            </Helmet>
            <Layout
              title=" Back to the Future The Musical | West End, London"
              description="The official website for Back to the Future The Musical. Now  playing at the Adelphi Theatre in London."
              booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/"
            >
              <LandingBlock />
              <Winner />
              <ReviewSlider />
              <TicketFlows />
              <FluxBlock booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?external_id=1622624308194.361242230648" />
              <AudioBlock
                stopMusic={this.stopMusic}
                stopVideo={this.stopVideo}
                videoStopped={this.state.videoStopped}
                musicStopped={this.state.musicStopped}
                title="HEAR SOME HEAVY TUNES FROM THE SHOW"
              />
              {/* <ChildrenInNeed /> */}
              <GreatScottBlock
                stopMusic={this.stopMusic}
                stopVideo={this.stopVideo}
                videoStopped={this.state.videoStopped}
                musicStopped={this.state.musicStopped}
                booking="https://booking.lwtheatres.co.uk/event/7c05f941-0adb-ea11-a9ca-064c84cbe4ea/?external_id=1622624308194.361242230648"
              />
              <QRcodeBlock />
              
            </Layout>
          </>
        );
      }
    }
  }
}

export default IndexPage;
