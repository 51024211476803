import React, { Component } from "react"
import { Container } from "reactstrap"
import { TimelineMax, Power1 } from "gsap"
import styled, { css } from "styled-components"
import ReactPlayer from "react-player"
import { media } from "utils/Media"
import logo from "images/BTTF-LOGO-2020-NEW.png"
import logoMobile from "images/BTTF-LOGO-2020-NEW.png"
import {graphql, StaticQuery} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FlashVoucher from "../shared/FlashVoucher"
import adelphiLogo from "images/adelphi-logo.svg"

const LandingContainer = styled(Container)`
    position: relative;
    background-color: black;
    width: 100%;
    padding: 0; 
    overflow: hidden;

    @media ${media.lg} {
        min-height: 100vh;
    }
    /* display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: center; */
    
     .lines {
        position: absolute;
        z-index: 1;
        background-color: ${props => props.theme.colors.red};
        height: 2px;
        width: 100vw;
        bottom: 30px;
        transition: all .4s ease;
        left: 0;
        
        @media ${media.lg} {
            bottom: 80px;
        }

        &:after,
        &:before {
            content: "";
            position: absolute;
            right: 0;
            z-index: 9;
            background-color: ${props => props.theme.colors.orange};
            height: 2px;
            width: 98%;
            top: .4rem;
        }

        &:before {
            width: 96%;
            top: .8rem;
            background-color: ${props => props.theme.colors.yellow};
        }
    }

    #videobg {
        display: block;
        position: relative;
        z-index: 1;
        width: 100vw;
        height: 65vh;
        /* min-height: 65vh; */
        overflow: hidden;
        pointer-events: none;
        left: 50%;
        transform: translateX(-50%);

        @media ${media.lg} {
            width: 90vw;
            height: 85vh;
            
            /* background: url(${props => props.$headerXl}) no-repeat center center; */
            background-size: cover;
        }

        .video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            min-height: 200%;
            min-width: 250%;
            z-index: -99;
            background-size: cover;

            @media ${media.lg} {
                min-height: 140%;
                min-width: 140%;
            }
        }

        &:after {
            content: "";
            background-color: rgba(0, 0, 0, .45);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -98;
        }
    }
`

const LogoWrap = styled.div`
    position: absolute;
    left: 50%;
    top: 60%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 80%;
    max-width: 1400px;

    @media ${media.md} {
        top: 40%;
    }
`

const LogoContainer = styled.div`
    width: 100%;
    padding: 1rem 0;
    display: flex;
    justify-content: center;
    z-index: 2;

    img {
        width: 100%;
        opacity: 0;
        height: 100%;

        @media ${media.md} {
            transform: scale(10);
            width: 70%;
            max-width: 60vh;
        }
    }

    &.transform-none {
        transform: none !important;
    }
`

const DestinationText = styled.div`
    
    padding: 1rem 1.5rem 3rem;
    width: 100%;
    max-width: 620px;
    
    @media ${media.md} {
        position: absolute;
        z-index: 1;
        bottom: 0;
        left: 50%;
        transform: translateX(-50%);
        padding: 1rem 1.5rem;
    }

    @media ${media.lg} {
        max-width: 820px;
    }

    h1, h2 {
        padding: 0;
        margin: 0;
        color: white;
        text-align: center;
        position: relative;
        z-index: 1;
        
        @media ${media.md} {
            font-size: 2.5rem;
        }

        span {
            display: block;
            letter-spacing: .15rem;
            font-family: ${props => props.theme.font.family.base};
            font-size: 70%;
        }
    }
`

const VideoBg = styled.div`
    @media ${media.lg} {
        background: url(${props => props.$headerXl}) no-repeat center center;
    }
`

const IntroText = styled.div`
    ${props => props.$spacer && css`
        width: 100%;
        height: 1rem;
        @media ${media.md} {
            height: 1.5rem;
        }
    `}
    /* background-color: black;
    padding: 1rem;
    color: white;
    text-align: center;
    z-index: 2;
    width: 100%;
    border-top: 2px solid white;

    @media ${media.md} {
        position: absolute;
        top: 0;
    }

    img {
        max-width: 150px;
        padding: 0 0 1rem 0;

        @media ${media.md} {
            padding: 0 2rem 0 0;
        }
    }

    h2 {
        padding: 0;
        margin: 0;
        color: white;
        font-family: ${props => props.theme.font.family.medium};
        font-size: ${props => props.theme.font.size.md};
    } */
`


class LandingBlockTheatres extends Component {

    constructor(props) {
        super(props)
        this.state = {
            logo: "",
            playing: false
        }

        this.masterLogoTimeline = new TimelineMax({ paused: true })
        this.setLogo = this.setLogo.bind(this)
        this.animation = this.animation.bind(this)
        this.play = this.play.bind(this)
        this.mouseMoveTilt = this.mouseMoveTilt.bind(this)
        this.orientationTilt = this.orientationTilt.bind(this)
        this.onReady = this.onReady.bind(this)
    }

    componentDidMount() {
        // create animation
        this.setLogo()
        window.addEventListener('resize', this.setLogo)
        this.animation()
        this.play()

        if (window.DeviceOrientationEvent && window.innerWidth < 768) {
            window.addEventListener("deviceorientation", this.orientationTilt)
        } else {
            window.addEventListener('mousemove', this.mouseMoveTilt)
        }
    }

    componentWillUnmount() {
        // Kill on unmount
        this.masterLogoTimeline.kill()
        window.removeEventListener('mousemove', this.mouseMoveTilt)
        window.removeEventListener("deviceorientation", this.orientationTilt)
        window.removeEventListener('resize', this.setLogo)
    }

    onReady() {
        this.setState({ playing: true })
    }

    setLogo() {
        if (typeof window !== 'undefined') {
            const logoSVG = window.innerWidth < 768 ? logoMobile : logo
            this.setState({ logo: logoSVG })
        }
    }

    play() {
        this.masterLogoTimeline.play()
    }

    animation() {
        const logoTimeline = new TimelineMax()
        const discoverTimeline = new TimelineMax()

        logoTimeline
            .from(this.logo, 0, { scale: 10, opacity: 0 })
            .to(this.logo, 1.5, { scale: 1, opacity: 1 })

        this.masterLogoTimeline
            .add(logoTimeline)
            .add(discoverTimeline)
    }

    mouseMoveTilt(e) {
        const tiltTimeline = new TimelineMax()

        // Detect mouse position
        let xPos = (e.clientX / window.innerWidth) - 0.5;
        let yPos = (e.clientY / window.innerHeight) - 0.5;

        // Tilt the hero container
        tiltTimeline.to(this.logoContainer, 0.6, {
            rotationY: 15 * xPos,
            rotationX: 15 * yPos,
            ease: Power1.easeOut,
            transformPerspective: 800,
            transformOrigin: "center center",
        });
    }

    orientationTilt(e) {
        const tiltTimeline = new TimelineMax()
        let yPos = Math.round(e.gamma);

        if (yPos >= 0) {
            yPos = yPos < 10 ? yPos : 10;
        } else {
            yPos = yPos > - 10 ? yPos : - 10;
        }

        // Tilt the hero container
        tiltTimeline.to(this.logoContainer, 0.6, {
            rotationY: yPos,
            ease: Power1.easeOut,
            transformPerspective: 800,
            transformOrigin: "center center"
        });
    }

    render() {

        return (
            <>
                <LandingContainer fluid className="pb-md-5 pb-lg-0">
                    {/* <IntroText>
                        <h2>
                            <a href="https://lwtheatres.co.uk/theatres/adelphi/" target="_blank" rel=" noopener">
                                <img src={adelphiLogo} alt="Adelphi Theatre"/>
                            </a>
                            <br className="d-md-none"/>
                            IMPORTANT TIME TRAVEL INFORMATION <a
                            href="https://lwtheatres.co.uk/latest-information-coronavirus/"
                            target="_blank"
                            rel="noopener ">HERE</a>
                            <br/>
                    
                        </h2>
                    </IntroText> */}
                    <IntroText />
                    <VideoBg id="videobg" $headerXl={this.props.data.headerXl.childImageSharp.gatsbyImageData.images.fallback.src}>
                        <div className="d-none d-lg-block">
                            <ReactPlayer
                                url='https://www.youtube.com/watch?v=jINwFutYYSs'
                                config={{
                                    youtube: {
                                        playerVars: {
                                            playerVars: { // https://developers.google.com/youtube/player_parameters
                                                autoplay: 0,
                                                controls: 0,
                                                autohide: 1,
                                                rel: 0,
                                                modestbranding: 1,
                                                showinfo: 0,
                                                loop: 1,
                                                mute: 1,
                                                playsinline: 1
                                            }
                                        }
                                    }
                                }}
                                playing={this.state.playing}
                                muted
                                loop
                                playsInline
                                onReady={this.onReady}
                                style={{ opacity: this.state.playing ? "1" : "0", transition: "all .5s ease" }}
                                className="video"
                            />
                        </div>
                        {!this.state.playing &&
                        <>
                            <GatsbyImage image={getImage(this.props.data.headerXs)}

                                         alt=""
                                         title=""
                                         className="img-fluid d-block d-sm-none"
                            />
                            <GatsbyImage image={getImage(this.props.data.headerSm)}

                                         alt=""
                                         title=""
                                         className="img-fluid d-none d-sm-block d-lg-none"
                            />
                        </>
                        }
                        <DestinationText>
                            <h1 className="h2">
                                THIS SHOW WILL CHANGE MUSICAL THEATRE HISTORY
                            </h1>
                        </DestinationText>
                    </VideoBg>
                    <div className="lines" />
                    <LogoWrap>
                        <LogoContainer ref={logoContainer => this.logoContainer = logoContainer} id="logoContainer">
                            <img src={this.state.logo} alt="Back To The Future The Musical" ref={logo => this.logo = logo} />
                        </LogoContainer>
                    </LogoWrap>
                </LandingContainer>
                {/* <FlashVoucher /> */}
            </>
        )
    }

}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                headerXs: file(relativePath: { eq: "HomePage/bg-home-header-576-new.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(width: 575)
                    }
                }
                headerSm: file(relativePath: { eq: "HomePage/bg-home-header-991-new.jpg" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991)
                    }
                }
                headerXl: file(relativePath: { eq: "HomePage/bg-home-header-1920-new.jpg" }) {
                    childImageSharp {
                         gatsbyImageData(width: 1920)
                    }
                }
            }
		`}
        render={data => (
            <LandingBlockTheatres data={data}/>
        )}
    />
)