import React, {Component} from 'react';
import MegaphoneAnimation from "../Animations/MegaphoneAnimation";
import Slider from 'react-slick'
import styled from "styled-components";
import {media} from "utils/Media"
import {graphql, StaticQuery} from "gatsby";
import StyledButton from "../shared/StyledButton";
import arrowLeft from '../../images/icons/btn-arrows.svg'

const settings = {
    slidesToShow: 4,
    dots: false,
    arrows: true,
    swipe: true,
    swipeToSlide: true,
    responsive: [
        {
            breakpoint: 768,
            settings: {
                arrows: true,
                slidesToShow: 2
            }
        },
        {
            breakpoint: 575,
            settings: {
                arrows: true,
                slidesToShow: 1
            }
        }
    ]
}

const Content = styled.div`
    text-align: center;
    padding: 2rem 0;
`

const TwitterSlider = styled(Slider)`
    position: relative;
    z-index: 2;
    text-align: center;
    width: calc(100% - 80px);
    margin: 0 auto;
    
    @media ${media.md} {
        margin-bottom: 2rem;
        width: 100%;
    }
    
    .slick-track {
        display: flex;
        align-items: flex-start;
        flex-wrap: nowrap;
        justify-content: center;
    }
    
    .slick-slide{
        padding: 0 25px;
        transition: all 0.3s ease;
        height: auto;
    }
    
    .slick-prev, .slick-next {
        width: 23px;
        height: 32px;
        top: 50%;
        
        @media ${media.sm} {
            width: 23px;
            height: 32px;
        }
        
        &:focus {
            outline: 5px auto -webkit-focus-ring-color !important;
        }
    }
    
    .slick-next {
        right: -10px;
        
        &:before {
            transform: rotate(180deg);
            content: '';
            background: url(${arrowLeft}) no-repeat center center;
            background-size: contain;
            position: absolute;
            right: -10px;
            top: 0;
            width: 100%;
            height: 100%;
        }
    }
    
    .slick-prev {
        left: -10px;
        
        &:before {
            content: '';
            background: url(${arrowLeft}) no-repeat center center;
            background-size: contain;
            position: absolute;
            left: -10px;
            top: 0;
            width: 100%;
            height: 100%;
            
            @media ${media.xl} {
                left: -20px;
            }
        }
    }
`


class MegaphoneBlockFamilies extends Component {
    render() {
        const sliderItems = this.props.data.allTwitterFamiliesJson.edges.map(({node}, i) => {
            return <div
                key={i}
                className="slider-item"
            >
                <div dangerouslySetInnerHTML={{__html: node.copy}}/>
                <p className="small">{node.person}</p>
            </div>
        })
        return (
            <Content>
                <MegaphoneAnimation/>

                <TwitterSlider
                    {...settings}
                    className="PhotoGallery"
                    ref={slider => (this.carousel = slider)}
                >
                    {sliderItems}
                </TwitterSlider>
            </Content>
        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                allTwitterFamiliesJson {
                    edges {
                        node {
                            copy
                            person
                        }
                    }
                }
            }
		`}
        render={data => (
            <MegaphoneBlockFamilies data={data}/>
        )}
    />
)