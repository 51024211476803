import React, { useEffect, useState, useRef} from 'react'
import {Container} from "reactstrap"
import { StaticImage } from 'gatsby-plugin-image';
import styled, { css } from "styled-components"
import gsap from 'gsap'
import {media} from "utils/Media"
import logo from "images/BTTF-LOGO-2020-NEW.png"
import logoMobile from "images/BTTF-LOGO-2020-NEW.png"
import adelphiLogo from "images/adelphi-logo.svg"
import {graphql, useStaticQuery} from "gatsby";
import { GatsbyImage, getImage } from "gatsby-plugin-image"

import OriginalWestEndCast from "../shared/OriginalWestEndCast"

import videoLink from '../../videos/london.mp4'

const LandingContainer = styled(Container)`
    position: relative;
    background-color: black;
    width: 100%;
    padding: 0; 
    overflow: hidden;
    #videobg {
        display: block;
        position: relative;
        z-index: 1;
        width: 100vw;
        height: 65vh;
        overflow: hidden;
        pointer-events: none;
        left: 50%;
        transform: translateX(-50%);

        @media ${media.lg} {
            width: 90vw;
            height: 85vh;
            background-size: cover;
        }

        .video {
            position: absolute;
            top: 50%;
            left: 50%;
            transform: translateX(-50%) translateY(-50%);
            min-height: 200%;
            min-width: 250%;
            z-index: -99;
            background-size: cover;

            @media ${media.lg} {
                min-height: 140%;
                min-width: 140%;
            }
        }

        &:after {
            content: "";
            background-color: rgba(0, 0, 0, .45);
            position: absolute;
            top: 0;
            left: 0;
            width: 100%;
            height: 100%;
            z-index: -98;
        }
    }
`

const LogoWrap = styled.div`
    position: absolute;
    left: 50%;
    top: 57%;
    // revert back to top: 47%;
    transform: translate(-50%, -50%);
    z-index: 10;
    width: 80%;
    max-width: 1400px;

    @media ${media.md} {
        top: 50%;
        // revert back to top: 40%;
    }
`

const LogoContainer = styled.div`
text-align: center;
max-width: 650px;
margin: 0 auto;
opacity: 0;
`

const IntroText = styled.div`
    ${props => props.$spacer && css`
        width: 100%;
        height: 1rem;
        @media ${media.md} {
            height: 1.5rem;
        }
    `}
    /* background-color: black;
    padding: 1rem;
    color: white;
    text-align: center;
    z-index: 2;
    width: 100%;
    border-top: 2px solid white;

    @media ${media.md} {
        position: absolute;
        top: 0;
    }
  
  &.noBorder {
    border: none;
  }

    img {
        max-width: 150px;
        padding: 0 0 1rem 0;

        @media ${media.md} {
            padding: 0 2rem 0 0;
        }
    }

    h2 {
        padding: 0;
        margin: 0;
        color: white;
        font-family: ${props => props.theme.font.family.medium};
        font-size: ${props => props.theme.font.size.md};
    } */
`


const VideoBg = styled.div`
    @media ${media.lg} {
        background: url(${props => props.$headerXl}) no-repeat center center;
    }
`


export default function LandingBlock() {
    const videoRef = useRef()
    const [playing, setPlaying] = useState(false)

    useEffect(() => {
        if(videoRef.current.paused) {
            videoRef.current.play()
            setPlaying(true)
        }
    }, [])

    useEffect(() => {
        gsap.set('#logoContainer', { position: 'absolute', top: '50%', left: '50%', transform: 'translate(-50%, -50%) scale(4)' });
        gsap.to('#logoContainer', { duration: 2.5, opacity: 1, scale: 1, ease: 'power3.out' });
      }, []); 

    const { headerXs, headerSm, headerXl  } = useStaticQuery(graphql`
    query {
        headerXs: file(relativePath: { eq: "HomePage/bg-home-header-576-new.jpg" }) {
            childImageSharp {
                 gatsbyImageData(width: 575)
            }
        }
        headerSm: file(relativePath: { eq: "HomePage/bg-home-header-991-new.jpg" }) {
            childImageSharp {
                gatsbyImageData(width: 991)
            }
        }
        headerXl: file(relativePath: { eq: "HomePage/bg-home-header-1920-new.jpg" }) {
            childImageSharp {
                 gatsbyImageData(width: 1920)
            }
        }
    }
`)
  return (
    <>
    <LandingContainer fluid>
        <IntroText $spacer/>
        <VideoBg id="videobg" $headerXl={headerXl.childImageSharp.gatsbyImageData.images.fallback.src}>
        
              <video
                playsInline
                muted
                loop
                ref={videoRef}
                className='video'
                style={{opacity: playing ? "1" : "0", transition: "all .5s ease"}}
            >
                <source src={videoLink} type="video/mp4" />
            </video>
            <OriginalWestEndCast />
            {!playing &&
            <>
                <GatsbyImage image={getImage(headerXs)}

                    alt="BTTF Background Image"
                    title=""
                    className="img-fluid d-block d-sm-none"
                />
                <GatsbyImage image={getImage(headerSm)}

                    alt="BTTF Background Image"
                    title=""
                    className="img-fluid d-none d-sm-block d-lg-none"
                />
            </>
            }
            {/*<DestinationText>*/}
            {/*    <CountdownBlock />*/}
            {/*</DestinationText>*/}
        </VideoBg>

        <LogoWrap>
            <LogoContainer  id="logoContainer">
                {/* <img src={this.state.logo} alt="Back To The Future The Musical" ref={logo => this.logo = logo}/> */}
                <StaticImage src="../../images/BTTF-LOGO-2020-NEW.png" alt=" BTTF Logo" placeholder='none' />
            </LogoContainer>
        </LogoWrap>
    </LandingContainer>
    {/* <FlashVoucher /> */}
</>
  )
}
