import React, {Component} from 'react';
import {graphql, StaticQuery} from "gatsby";
import styled from "styled-components";
import {media} from "utils/Media"
import { GatsbyImage, getImage } from "gatsby-plugin-image"
import FluxCapacitatorAnimation from "../Animations/FluxCapacitatorAnimation"
import Counter88Animation from "../Animations/Counter88Animation"
import StyledButton from "../shared/StyledButton";
import {Row, Col} from "reactstrap"

const Content = styled.div`
    position: relative;
    margin: 0 auto;
    text-align: center;
    
`
const MartyDoc = styled.div`
    position: relative;
    width: 100%;
    text-align: center;
    
    @media ${media.lg} {
        display: flex;
        align-items: flex-start;
        justify-content: center;
    }
    
    .martyDocImage {
        width: 1022px;
    }
    
    .left, .right {
        width: 100%;
        position: relative;
        z-index: 2;
        
        @media ${media.lg} {
            width: 50%;
        }
    }
    
    .left {
        align-self: flex-end;
    }
    
    .inner {
        padding: 0 1rem;
        max-width: 530px;
        margin: 0 auto;
    }
    
    .stripe {
        width: 100%;
        height: 300px;
        z-index: 1;
        background-color: black;
        position: absolute;
        left: 0;
        bottom: 0;
        
        @media ${media.sm} {
            height: 340px;
        }
        
        @media ${media.md} {
            height: 400px;
        }
        
        @media ${media.lg} {
            height: 200px;
        }
        
        @media ${media.xl} {
            height: 200px;
        }
    }
`

class FluxBlockFilmFans extends Component {
    render() {
        return (
            <Content className="my-3 my-md-5">
                <MartyDoc>
                    <div className="left d-none d-lg-block">
                        <FluxCapacitatorAnimation smaller/>
                        <GatsbyImage image={getImage(this.props.data.martyDocBgLg)}
                            alt=""
                            title=""
                            className="img-fluid d-none d-lg-block martyDocImage"
                        />
                    </div>
                    <div className="right">
                        <div className="inner">
                            <FluxCapacitatorAnimation className="d-block d-lg-none" smaller/>
                            <p className="mb-lg-4">
                                Welcome to Hill Valley! The 1985 movie phenomenon
                                is finally transported to the stage by the
                                film’s original creative team, Bob Gale and Robert
                                Zemeckis. This is heavy!</p>

                            <p className="mb-lg-4">Take an electrifying ride back in time with Doc Brown and
                                Marty McFly in this ground-breaking new musical production.</p>

                            <p className="mb-lg-4">Featuring all the characters and songs you
                            love alongside a brand-new score and a few added
                            surprises, <strong>Back&nbsp;to&nbsp;the&nbsp;Future:&nbsp;The&nbsp;Musical</strong> is the
                            hilarious, high-voltage story you know... but with
                                added plutonium.</p>

                            <p className="mb-lg-4">The adventure wouldn’t be complete without a
                            DeLorean, because if you’re gonna build a film into
                                a musical... why not do it with some style?</p>

                            <p className="mb-lg-4">Time circuits on. Flux capacitor fluxing.
                            Strap yourself in, because when this show hits 88mph,
                                you’re gonna see some serious… entertainment.</p>

                            <p className="mb-lg-4">&nbsp;</p>

                            <p className="mb-0 text-uppercase font-weight-bold">PRICES FROM £19.55</p>

                            <StyledButton $hide className="mt-2 mb-5 mx-auto" $inverse target="_blank" rel=" noopener" href={this.props.booking}>
                                Book Tickets
                            </StyledButton>

                            <div style={{opacity: 0}} className="d-none d-lg-block mb-lg-2">
                                <Counter88Animation/>
                            </div>
                        </div>
                    </div>
                    <div className="left d-lg-none position-relative">
                        <GatsbyImage image={getImage(this.props.data.martyDocBgXs)}

                            alt=""
                            title=""
                            className="img-fluid d-sm-none"
                        />
                        <GatsbyImage image={getImage(this.props.data.martyDocBgSm)}

                            alt=""
                            title=""
                            className="img-fluid d-none d-sm-block d-lg-none"
                        />
                        <div className="d-lg-none">
                            <Counter88Animation/>
                        </div>
                    </div>
                    <div className="stripe">
                        <div className="d-none d-lg-block mb-lg-2 px-4">
                            <Row>
                                <Col xs={12} lg={6}>&nbsp;</Col>
                                <Col xs={12} lg={6}><Counter88Animation/></Col>
                            </Row>
                        </div>
                    </div>
                </MartyDoc>
            </Content>

        );
    }
}

export default (props) => (
    <StaticQuery
        query={graphql`
            query {
                martyDocBgXs: file(relativePath: { eq: "HomePage/pic-martydoc-576-new.png" }) {
                    childImageSharp {
                         gatsbyImageData(width: 575)
                    }
                }
                martyDocBgSm: file(relativePath: { eq: "HomePage/pic-martydoc-991-new.png" }) {
                    childImageSharp {
                        gatsbyImageData(width: 991)
                    }
                }
                martyDocBgLg: file(relativePath: { eq: "HomePage/pic-martydoc-1920-new.png" }) {
                    childImageSharp {
                       gatsbyImageData(width: 1028)
                    }
                }
            }
		`}
        render={data => (
            <FluxBlockFilmFans {...props} data={data}/>
        )}
    />
)
